.LoadingContainer{
    position: fixed;
    overflow: hidden;
    min-width: 100%;
    min-height: 100%;
    background-color: white;
    z-index: 9999;
}

.loadingDiv{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.loadingDiv img{
    max-width: 100%;
}

.loadingDiv h1{
    color: var(--accent-blue);
    font-size: 20px!important;
}