.TopBarControls{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    background-color: var(--accent-blue);
    position: fixed;
    margin-top: -48px;
    z-index: 1;
    left: 0;
}

.TopBarButton{
    max-width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TopBarButton #back,
.TopBarButton #address{
    display: inline-block;
    font-size: 14px;
    color: white;
    text-decoration: none;
}

.TopBarButton #back{
    cursor: pointer;
    line-height: 0;
    padding: 18px;
    font-weight: 700;
    width: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TopBarButton #address{
    margin-right: 20px;
    font-weight: 400;
}

.TopBarButton #back #icon,
#save #icon{
    margin-right: 5px;
}

.FooterInfo{
    padding: 10px;
    background-color: var(--accent-orange);
    color: white;
    border-bottom: 2px solid white;
}

.FooterInfo p{
    font-size: 15px!important;
}

.Footer{
    height: 50px;
    width: 100%;
    padding-bottom: 20px;
    padding-top: 5px;
    background-color: var(--accent-blue);
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.requiredText{
    position: absolute;
    font-size: 14px!important;
    bottom: 2px;
    left: 2px;
}

.Footer p{
    color: white;
    font-size: 16px;
    display: inline-block;
}

.Footer div{
    width: 50%;
    text-align: center;
}

.Footer .ScrollBtn{
    padding: 8px 16px;
    background-color: var(--accent-orange);
    color: white;
    height: 20px;
    line-height: 16px;
    width: auto;
    display: inline-block;
    margin: auto;
    border: 2px solid white;
    border-radius: 8px;
    cursor: pointer;
}

.ScrollBtn p{
    cursor: pointer!important;
}

.Footer #save{
    cursor: pointer;
    padding: 12px;
}

.Footer button{
    height: 42px;
    margin-right: 4px;
    display: block;
    width: 100%;
    min-width: 120px;
    max-width: 300px;
    background-color: var(--accent-orange);
    color: white;
    font-weight: 600;
    font-size: 16px;
    border: 2px solid white;
    border-radius: 8px;
    cursor: pointer;
}

.InfoTable{
    padding: 16px;
    word-break: break-word;
    width: 100%;
}

.InfoTable tbody tr{
    width: 100%;
}

.InfoTable tbody .Keys{
    font-weight: 600;
    padding-right: 20px;
    word-break: normal!important;
    width: 40%;
}

.FormContainer{
    padding-top: 120px!important;
    padding-bottom: 75px;
    border-left: 1px solid rgb(156, 156, 156);
    border-right: 1px solid rgb(156, 156, 156);
}

.QuestionBox{
    padding: 16px;
    border-bottom: 1px solid var(--background-gray);
    position: relative;
}

.SubContainer{
    position: relative;
}

.HeaderBox{
    display: block;
    text-align: left;
    cursor: pointer;
}

.HeaderBox h1{
    display: inline-block;
    margin-bottom: 8px;
    margin-right: 8px;
}

.HeaderBox #icon{
    font-size: 40px;
    color: var(--accent-blue);
    margin-top: -10px;
}

.HeaderBox #titleBox{
    display: flex;
    justify-content: space-between;
}

.QuestionExtra{
    margin: 5px 0;
}

.false{
    display: none;
}

#subTitle{
    color: var(--accent-blue);
    padding: 16px;
    font-size: 15px;
    background-color: var(--background-gray);
    font-weight: bold;
    text-align: center;
    border-top: 1px solid var(--accent-orange);
}

#subTitleSub{
    color: var(--accent-blue);
    padding: 4px 16px;
    font-size: 15px;
    background-color: var(--background-gray);
    font-weight: bold;
    text-align: center;
    border-top: 1px solid var(--accent-orange);
}

#dropdownContent{
    margin: 14px 0;
}

#required{
    color: var(--accent-orange);
    margin-left: 2px;
}

#remaining{
    cursor: default;
}

.InfoTable a{
    color: white;
    text-decoration: none;
    padding: 0 5px;
    border-radius: 50px;
    background-color: var(--accent-blue);
}

#dropdownContent label{
    cursor: pointer;
    text-align: center;
    padding-left: 10px;
}

#dropdownContent label span{
    vertical-align: middle;
}

#dropdownContent article{
    white-space: pre-wrap;
    max-width: 100%;
    padding: 8px;
    font-weight: 600;
    font-size: 14px;
    outline: none;
    border-bottom: 2px solid var(--accent-orange);
    background-color: var(--background-gray);
}

[contenteditable=true]:empty:before {
    content: attr(placeholder);
    display: block;
    color: #aaa;
    font-size: 12px;
    height: 19px!important;
  }

#dropdownContent article:focus{
    border-bottom: 2px solid var(--accent-blue);
}

#dropdownContent #closeFooter{
    width: 50%;
    min-width: 130px;
    height: 30px;
    text-align: center;
    color: var(--accent-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-gray);
    margin: 24px auto -31px;
    border-radius: 12px 12px 0 0;
    cursor: pointer;
    border: 1px solid var(--accent-blue);
    border-bottom: none;
}

#subQuestions h1{
    margin: 30px 0 20px;
}

.ImageUpload,
.ExtraUpload{
    background-color: var(--accent-blue);
    color: white;
    font-weight: 600;
    border-radius: 8px;
    padding: 8px!important;
    cursor: pointer;
}

.ExtraUpload{
    margin-bottom: 20px;
    display: block;
    width: 150px;
}

#imageBox{
    position: relative;
    width: 100%;
    margin: 0 5px 20px;
    display: inline-block;
    vertical-align: top;
    max-width: 300px;
}

#imageBox article{
    margin-top: 5px;
}

#removeButton{
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    background-color: var(--accent-orange);
    color: white;
    height: 28px;
    width: 28px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#mainImage{
    margin-top: 20px;
}

.Image{
    object-fit: contain;
    max-width: 100%;
    margin: auto;
    border-radius: 16px;
    display: block;
}

#answerCompact{
    word-break: break-word;
}

#answerCompact #icon{
    font-size: 14px;
    color: green;
}

#flexOptions{
    display: flex;
    flex-wrap: wrap;
}

#flexOptions .Option{
    display: inline-block;
    width: 50%;
    max-width: 200px;
    margin: 0!important;
}

.Option{
    margin: 15px 0;
}

.sigCanvas{
    background-color: var(--background-gray);
    border-bottom: 2px solid var(--accent-orange);
    display: block;
    cursor: crosshair;
}

#clearSig,
#saveSig{
    padding: 4px 8px;
    border-radius: 4px;
    background-color: var(--accent-orange);
    color: white;
    display: inline-block;
    margin: 10px 0;
    cursor: pointer;
}

#clearSig{
    margin-left: 20px;
}

#saveSig{
    background-color: var(--accent-blue);
}

#locationDiv{
    display: flex;
    flex-direction: row;
    margin: 12px;
    text-decoration: none;
    color: inherit;
}

#locationDiv:hover p{
    text-decoration: underline;
}

#locationDiv #icon{
    color: var(--accent-blue);
}

#locationDiv div{
    display: inline-block;
}

#locationDiv p{
    font-size: 14px;
}

#locationDiv span{
    font-size: 13px;
}

input[type=number],
input[type=text]{
    width: 50%;
    outline: none;
    height: 30px;
    border-width: 0 0 2px;
    border-color: var(--accent-orange);
    background-color: var(--background-gray);
}

.RangeIndicator{
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: space-between;
}
.RangeIndicator p{
    display: inline;
}
#rangeCurrent{
    display: inline-block;
    margin-left: 5px;
    vertical-align: top;
}
input[type="range"]{
    width: 100%;
    max-width: 300px;
    cursor: pointer;
}

input[type=number]:focus,
input[type=text]:focus,
input[type=password]:focus{
    border-color: var(--accent-blue);
}

input[type="file"] {
    display: none;
}

input[type="checkbox"],
input[type="radio"]{
    vertical-align: middle;
    cursor: pointer;
    margin: 0;
}

.DisabledQuestion{
    opacity: 0.2;
}
.DisabledQuestion .HeaderBox{
    cursor: default;
}

.ScrollHelper{
    position: absolute;
    top: -40vh;
}

.highlighted{
    animation: 0.25s ease infinite splash;
}

@keyframes splash {
    from {
        color: black;
    }
    30%{
        color: var(--accent-orange);
    }
    70%{
        color: var(--accent-orange);  
    }
    to {
        color: black;
    }
}

.modal{
    padding: 12px 12px 6px;
}

.modal > .content p{
    margin: 24px 0;
}

.modal > .content img{
    width: 75%;
    margin: 0 auto;
    display: block;
}

.modal > .actions{
    display: flex;
    justify-content: center;
}

.modal > .actions > button{
    display: block;
    background-color: var(--accent-blue);
    color: white;
    font-weight: 600;
    font-size: 16px;
    border: 2px solid white;
    border-radius: 4px;
    cursor: pointer;
    padding: 6px 18px;
}

.popup-content{
    border-radius: 8px;
    width: 50%;
    max-width: 350px;
    min-width: 200px;
}
@media only screen and (max-width: 700px) {
    .Image{
        max-width: 100%;
        margin: auto;
        border-radius: 16px;
        display: block;
    }
  }

  @media only screen and (max-width: 300px) {
    .TopBarButton #address span,
    #remaining{
        display: none;
    }
  }