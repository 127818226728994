.NavContainer{
    width: 100%;
    height: 70px;
    background-color: var(--background-gray);
    border-bottom: 2px solid var(--accent-orange);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 1;
    left: 0;
}

.NavContainer img{
    max-height: 64px;
    margin: 3px;
}
 
.NavContainer p{
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    color: var(--accent-blue);
    cursor: pointer;
}

.NavContainer #signOutButton{
    background-color: var(--accent-orange);
    color: white;
    font-size: 16px;
    width: 70px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selected{
    background-color: var(--accent-orange);
    color: white!important;
}

@media only screen and (max-width: 300px) {
    .NavContainer p {
        display: none;
    }
  }