.LoginContainer{
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    text-align: center;
}

.LoginContainer img{
    max-width: 100%;
    margin: 20px 0 10px;
}

.LoginContainer h1{
    font-size: 30px;
    margin-bottom: 20px;
    color: var(--accent-blue);
}

.LoginContainer form{
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 500px;
    margin: auto;
}

.LoginContainer form input{
    height: 40px;
    background-color: transparent;
    outline: none;
    border-width: 0 0 2px;
    border-color: var(--accent-orange);
    margin-bottom: 15px;
    width: 100%;
}

.LoginContainer form input::placeholder {
    opacity: 0.5;
}

.LoginContainer form input:focus{
    background-color: var(--background-gray)
}

.LoginContainer .LoginButton{
    height: 50px;
    border-radius: 70px;
    margin: 25px auto 35px;
    border: none;
    background-color: var(--background-gray);
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    width: 100%;
    max-width: 300px;
}

#psw{
    position: relative;
    margin: auto;
    width: 100%;
}

#showPSW{
    position: absolute;
    right: 0;
    top: 0;
    color: var(--accent-blue);
    padding: 14px;
    cursor: pointer;
}

#ForgotPSW{
    text-align: initial;
}