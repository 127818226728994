.NotFoundContainer{
    text-align: center;
}

.NotFoundContainer img{
    width: 40%;
    max-width: 300px;
}

.NotFoundContainer p{
    font-size: 16px;
}