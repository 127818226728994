/* RESET CSS */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
    font-family: 'Open Sans', sans-serif;
	vertical-align: baseline;
    -webkit-tap-highlight-color: transparent;
}

html, body{
    height: 100%;
    width: 100%;
}
/*--------------------*/
/* DEFINE GLOBAL VARS */
:root {
    --accent-blue: #3d6ab2;
    --accent-orange: #f9a451;
    --background-gray: #f2edea;
  }
/*--------------------*/

:root{
    width: 100%;
    max-width: 700px;
    margin: auto;
}

hr {
    background-color: var(--accent-blue);
    border: 1px solid var(--accent-blue);
}

.Container{
    padding-top: 80px;
}

.Disabled{
    cursor: default!important;
    color:rgb(189, 189, 189)!important;
    background-color: var(--background-gray)!important;
}

.link{
    text-decoration: underline;
    color: inherit;
    text-decoration-thickness: 1px;
    font-size: 13px;
    cursor: pointer;
    text-align: initial;
    padding: 8px 0;
}

#error{
    padding: 0 20px;
    margin-bottom: 20px;
}

p{
    font-size: 14px;
}

#errorMsg{
    font-size: 14px;
}

#errorIcon{
    color: orange;
    margin-right: 5px;
}

@media only screen and (max-width: 380px) {
    body {
      font-size: 14px;
    }
  }