.DashboardContainer{
    height: 100%;
}

.DashboardContainer hr{
    margin: 10px 0 0;
    border: 1px solid black;
}

.Item{
    text-align: initial;
    padding: 20px 10px 20px 20px;
    background-color: var(--accent-blue);
    text-decoration: none;
    color: white;
    display: block;
    margin-bottom: 2px;
    font-size: 18px;
}

.Item p{
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
}

.Item #icon{
    margin-right: 20px;
}

#dashError{
    padding: 20px;
    display: block;
    font-size: 16px;
    text-align: center;
}

.dashboardOpen{
    margin-bottom: 20px;
}

#dashboardSubtitle{
    color: black;
    padding: 8px 16px 12px 8px;
    /* background-color: var(--background-gray); */
    font-weight: bold;
    text-align: start;
    font-size: 16px;
}

.dashboardSaved > .Item{
    background-color: var(--accent-orange)
}